export const getLocaleSuffix = (currentLocale: string, defaultLocale: string): string => {
    if (defaultLocale === currentLocale) {
        return ''
    }
    return `/${currentLocale}`
}

export const getLanguageAlternates = (
    baseUrl: string,
    pathUrl: string,
    currentLocale: string,
    defaultLocale: string,
    locales: string[],
): ReadonlyArray<{
    hrefLang: string;
    href: string;
}> => {

    return locales.map((loc) => {
        const localeSuffix = getLocaleSuffix(loc, defaultLocale)
        return {
            hrefLang: loc,
            href: `${baseUrl}${localeSuffix}${pathUrl}`
        }
    })

}